import { createAction } from '@/utility/actions';

import {
  STREAMING_REWARDS_GET,
  STREAMING_REWARDS_UPDATE,
  STREAMING_REWARDS_CLEAR,
  STREAMING_REWARDS_CHECK,
  STREAMING_REWARDS_CLAIM,
  VIRTUAL_CARD_GET_EXPANDED_DATA,
  VIRTUAL_CARD_CLEAR_EXPANDED_DATA,
  VIRTUAL_CARD_GET_IFRAME_TOKEN,
  STREAMING_REWARDS_GET_CLAIM_INST,
  STREAMING_REWARDS_SET_PLAY_ANIMATION,
  STREAMING_REWARDS_SET_ANIMATION_CARD_POS,
  STREAMING_REWARDS_GET_IMAGE_APPS,
  STREAMING_REWARDS_GET_APP_SEARCH,
  STREAMING_REWARDS_CLEAR_APP_SEARCH,
  STREAMING_REWARDS_COMPLETE_REDEEM,
  STREAMING_REWARDS_MANAGE_USED_TOKEN_DIALOG_STATE,
  STREAMING_REWARDS_CLAIM_REWARD_CODE,
  STREAMING_REWARDS_REISSUE_CARD,
} from './types';

/**
 * getStreamingRewards - slug, onError, onNotEligible
 */
export const getStreamingRewards = createAction(STREAMING_REWARDS_GET);

/**
 * updateStreamingRewards: data
 */
export const updateStreamingRewards = createAction(STREAMING_REWARDS_UPDATE);

/**
 * clearStreamingRewards
 */
export const clearStreamingRewards = createAction(STREAMING_REWARDS_CLEAR);

/**
 * checkStreamingRewards
 */
export const checkStreamingRewards = createAction(STREAMING_REWARDS_CHECK);

/**
 * claimStreamingRewards: params - token
 */
export const claimStreamingRewards = createAction(STREAMING_REWARDS_CLAIM);

/**
 * getVirtualCardExpandedData
 */
export const getVirtualCardExpandedData = createAction(VIRTUAL_CARD_GET_EXPANDED_DATA);

/**
 * clearVirtualCardExpandedData
 */
export const clearVirtualCardExpandedData = createAction(VIRTUAL_CARD_CLEAR_EXPANDED_DATA);

/**
 * getVirtualCardIframeToken - payment_method_id, onSuccess, onError
 */
export const getVirtualCardIframeToken = createAction(VIRTUAL_CARD_GET_IFRAME_TOKEN);

/**
 * getSRClaimInstruction
 */
export const getSRClaimInstruction = createAction(STREAMING_REWARDS_GET_CLAIM_INST);

/**
 * setPlayAnimation
 */
export const setPlayAnimation = createAction(STREAMING_REWARDS_SET_PLAY_ANIMATION);

/**
 * setPlayAnimation
 */
export const setAnimCardPosition = createAction(STREAMING_REWARDS_SET_ANIMATION_CARD_POS);

/**
 * getImageApps - params: slug(string)
 */
export const getImageApps = createAction(STREAMING_REWARDS_GET_IMAGE_APPS);

/**
 * getSRAppsSearch - params
 */
export const getSRAppsSearch = createAction(STREAMING_REWARDS_GET_APP_SEARCH);

/**
 * clearSRAppsSearch
 */
export const clearSRAppsSearch = createAction(STREAMING_REWARDS_CLEAR_APP_SEARCH);

/**
 * completeRedeemSR - slug, onSuccess
 */
export const completeRedeemSR = createAction(STREAMING_REWARDS_COMPLETE_REDEEM);

/**
 * manageSRUsedTokenDialog - slug, onSuccess
 */
export const manageSRUsedTokenDialog = createAction(
  STREAMING_REWARDS_MANAGE_USED_TOKEN_DIALOG_STATE,
);

/**
 * srClaimRewardCode - params, onError
 */
export const srClaimRewardCode = createAction(STREAMING_REWARDS_CLAIM_REWARD_CODE);

/**
 * reissueSRCard - slug, params
 */
export const reissueSRCard = createAction(STREAMING_REWARDS_REISSUE_CARD);

export default {
  getStreamingRewards,
  updateStreamingRewards,
  clearStreamingRewards,
  checkStreamingRewards,
  claimStreamingRewards,
  getVirtualCardExpandedData,
  clearVirtualCardExpandedData,
  getVirtualCardIframeToken,
  getSRClaimInstruction,
  setPlayAnimation,
  setAnimCardPosition,
  getImageApps,
  getSRAppsSearch,
  clearSRAppsSearch,
  completeRedeemSR,
  manageSRUsedTokenDialog,
  srClaimRewardCode,
  reissueSRCard,
};
