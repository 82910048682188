import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// UTILITY
import { trimCharacters } from '@/utility/common';
import setYupValidationSchema from '@/utility/validationSchema/validationSchema';
import { getS3ImageUrl } from '@/utility/content';

// COMPONENTS
import useActions from '@/hooks/useActions';
import { AppActions } from '@/store/actions';

// STYLES
import { StyledDialog } from './styles';

const dialogImg = getS3ImageUrl('/dialog-img.png');

const DialogConfirmZipcode = () => {
  const [t] = useTranslation();

  const { dialogIsOpen, dialogProps, ipApiData } = useSelector(({ app, ipApi }) => ({
    dialogIsOpen: app.confirmZipDialogIsOpen,
    dialogProps: app.confirmZipDialogProps,
    ipApiData: ipApi.data,
  }));

  const [closeDialog] = useActions([
    () => AppActions.manageConfirmZipDialogState.action({ state: false, props: {} }),
  ]);

  const zipcodeValue = dialogProps.zipcode || ipApiData?.zip || '';

  const handleChangeZipCode = (event, setFieldValue) => {
    const value = trimCharacters(event.target.value);

    setFieldValue('zipcode', value);
  };

  const handleValidate = (values, validationSchema) => {
    try {
      validateYupSchema(values, validationSchema, true, {
        requiredQuestions: ['zipcode'],
      });
    } catch (err) {
      return yupToFormErrors(err);
    }

    return {};
  };

  const handleSubmit = values => {
    if (dialogProps.callback) dialogProps.callback(values);

    closeDialog();
  };

  return (
    <StyledDialog
      open={dialogIsOpen}
      image={<img src={dialogImg} alt="hero" />}
      title={t('dialogConfirmZipcode.title')}
      titleProps={{ className: 'text-bold', variant: 'h2', component: 'h4' }}
      subtitle={t('dialogConfirmZipcode.subtitle')}
      maxWidth="md"
      hideCloseIcon
      hideCancelButton
      hideConfirmButton
    >
      <Formik
        initialValues={{ zipcode: zipcodeValue }}
        validate={values => handleValidate(values, setYupValidationSchema('zipSchema', t))}
        enableReinitialize
        validateOnMount
        onSubmit={() => {}}
      >
        {({ values, errors, touched, handleBlur, setFieldValue, isValid, isSubmitting }) => {
          return (
            <>
              <TextField
                className="zipcodeinput m-b-25"
                label={t(`inputs.enterZipCode`)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      className={Boolean(!errors.zipcode) && !!values.zipcode ? 'success' : ''}
                      position="start"
                    >
                      <i className="icon-check" />
                    </InputAdornment>
                  ),
                }}
                type="tel"
                name="zipcode"
                value={values.zipcode}
                onChange={e => handleChangeZipCode(e, setFieldValue)}
                onBlur={handleBlur}
                error={touched.zipcode && !!errors.zipcode}
                helperText={touched.zipcode && errors.zipcode}
              />
              <Button
                className={clsx('sizeExtraLarge dialog-cta__confirm-btn')}
                fullWidth
                color="primary"
                onClick={() => handleSubmit(values)}
                disabled={!isValid && !isSubmitting}
              >
                {t('buttons.submit')}
              </Button>
            </>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};

export default React.memo(DialogConfirmZipcode);
