import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

// UTILITY
import { getRandomId } from '@/utility/common';

// HOOKS
import useActions from '@/hooks/useActions';

// STORE
import { StreamingRewardsActions } from '@/store/actions';

// COMPONENTS
import Loader from '@/components/Loader';

const FintechCardContent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const randomId = React.useMemo(getRandomId, []);
  const iframeId = `iframe-container-${randomId}`;

  const { card } = useSelector(({ auth }) => ({
    card: auth.user?.card,
  }));

  const [getVirtualCardIframeToken] = useActions([
    (payment_method_id, onSuccess, onError) =>
      StreamingRewardsActions.getVirtualCardIframeToken.action({
        payment_method_id,
        onSuccess,
        onError,
      }),
  ]);

  useEffect(() => {
    setError('');

    if (window.Card && card?.payment_method_id) {
      setIsLoading(true);

      const onSuccess = respData => {
        // eslint-disable-next-line no-new
        new window.Card.Iframe(`#${iframeId}`, {
          authenticationKey: respData.payment_gateway_id,
          templateWithBalance: false,
          backgroundImageUrl: '',
          businessName: 'Partner',
          businessLogoUrl:
            'https://media.mybundle.tv/cp-uploads/MyBundleHorizontal-Whiteandgreen-07.png',
          primaryColor: '#212121',
          primaryFontcolor: '#FFFFFF',
          secondaryColor: '#303030',
          secondaryFontcolor: '#FFFFFF',
        });

        setIsLoading(false);
      };

      const onError = errMessage => {
        setError(errMessage);
        setIsLoading(false);
      };

      getVirtualCardIframeToken(card.payment_method_id, onSuccess, onError);
    }
  }, [window.Card]);

  return (
    <>
      <div id={iframeId} />
      {!isLoading && !!error && (
        <Typography className="p3" align="center">
          {error}
        </Typography>
      )}
      <Loader active={isLoading} bgColor="transparent" />
    </>
  );
};

export default React.memo(FintechCardContent);
