import { createAction } from '@/utility/actions';

import {
  APP_SET_MAINTENANCE_MODE,
  APP_HEALTH_CHECK,
  APP_EMAIL_UNSUBSCRIBE,
  APP_SET_PARTNER,
  APP_MANAGE_PROF_DRAWER_STATE,
  APP_SET_DEVICE_ORIENTATION,
  APP_SET_HEADER_HEIGHT,
  APP_CREATE_FEEDBACK,
  APP_GET_SEARCH,
  APP_GET_DICTIONARY,
  APP_CONTACT_US_REQUEST_DEMO,
  APP_GET_DOWNLOAD_APP_LINK_VIA_SMS,
  APP_MANAGE_CONFIRM_ZIP_DIALOG_STATE,
} from './types';

export const setMaintenanceMode = createAction(APP_SET_MAINTENANCE_MODE);
export const healthCheck = createAction(APP_HEALTH_CHECK);

/**
 * emailUnsubscribe - params: email
 */
export const emailUnsubscribe = createAction(APP_EMAIL_UNSUBSCRIBE);

/**
 * setPartner - params: partner
 */
export const setPartner = createAction(APP_SET_PARTNER);

/**
 * manageProfDrawerState - params: state, props
 */
export const manageProfDrawerState = createAction(APP_MANAGE_PROF_DRAWER_STATE);
/**
 * manageConfirmZipDialogState - params: state, props
 */
export const manageConfirmZipDialogState = createAction(APP_MANAGE_CONFIRM_ZIP_DIALOG_STATE);

export const setDeviceOrientation = createAction(APP_SET_DEVICE_ORIENTATION);

export const setHeaderHeight = createAction(APP_SET_HEADER_HEIGHT);

/**
 * createFeedback - params: data, callback
 */
export const createFeedback = createAction(APP_CREATE_FEEDBACK);

/**
 * getAppSearch - params: params(search)
 */
export const getAppSearch = createAction(APP_GET_SEARCH);

/**
 * getDictionary
 */
export const getDictionary = createAction(APP_GET_DICTIONARY);

/**
 * requestDemo - params: email(string), onSuccess(func)
 */
export const requestDemo = createAction(APP_CONTACT_US_REQUEST_DEMO);

/**
 * getDownloadAppLinkViaSMS - data: (phone(string), partner), onSuccess
 */
export const getDownloadAppLinkViaSMS = createAction(APP_GET_DOWNLOAD_APP_LINK_VIA_SMS);

export default {
  setMaintenanceMode,
  healthCheck,
  emailUnsubscribe,
  setPartner,
  manageProfDrawerState,
  manageConfirmZipDialogState,
  setDeviceOrientation,
  setHeaderHeight,
  createFeedback,
  getAppSearch,
  getDictionary,
  requestDemo,
  getDownloadAppLinkViaSMS,
};
