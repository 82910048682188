import axios from 'axios';
import { fork, put, select, call } from 'redux-saga/effects';

// CONSTANTS
import {
  STREAMING_REWARDS_GET,
  STREAMING_REWARDS_CHECK,
  STREAMING_REWARDS_CLAIM,
  VIRTUAL_CARD_GET_EXPANDED_DATA,
  VIRTUAL_CARD_GET_IFRAME_TOKEN,
  STREAMING_REWARDS_GET_CLAIM_INST,
  STREAMING_REWARDS_GET_IMAGE_APPS,
  GET_DISCOVER,
  STREAMING_REWARDS_COMPLETE_REDEEM,
  STREAMING_REWARDS_CLAIM_REWARD_CODE,
  STREAMING_REWARDS_REISSUE_CARD,
} from '@/constants/api';
import { confirmZipcodeAllowed } from '@/constants/streamingRewards';

// UTILITY
import getErrorMessage from '@/utility/errors';
import { getLink } from '@/utility/routes';
import { getPartnerData, getUserData, showErrorNotification } from '@/utility/saga';
import bugsnagClient from '@/utility/bugsnagClient';
import { externalLink, getExternalUrlParams } from '@/utility/analytics';
import { updateUrl } from '@/utility/common';

// STORE
import reduxStore from '@/store/configureStore';
import { StreamingRewardsActions, NotifActions, UserActions, AppActions } from '@/store/actions';
import { PartnerSelectors, AuthSelectors } from '@/store/selectors';
import { userGetCardsSaga } from '@/store/saga/user';
import { getIPInfoSaga } from '@/store/saga/ipApi';
import { getWPPageSaga } from './wpBlog';

// LOCALIZATION
import { t } from '@/locale/i18n';

const {
  getStreamingRewards,
  checkStreamingRewards,
  claimStreamingRewards,
  getVirtualCardExpandedData,
  getVirtualCardIframeToken,
  getSRClaimInstruction,
  getImageApps,
  getSRAppsSearch,
  completeRedeemSR,
  srClaimRewardCode,
  reissueSRCard,
} = StreamingRewardsActions;
const { userEditProfile, userGetContentCount } = UserActions;
const { pushSuccessNotificationAction, pushErrorNotificationAction } = NotifActions;
const { manageConfirmZipDialogState } = AppActions;

export function* getStreamingRewardsSaga(action) {
  yield put(getStreamingRewards.start());

  const { slug, onSuccess, onError, onNotEligible } = action.payload;
  const partnerData = yield select(PartnerSelectors.getPartnerData);
  const isAuth = yield select(AuthSelectors.getIsAuth);
  const userData = yield call(getUserData);

  try {
    const { data: respData } = yield axios.get(getLink(STREAMING_REWARDS_GET, { slug }), {
      params: { partner: partnerData.id },
      cache: { ignoreCache: true },
    });

    yield put(
      getStreamingRewards.success({
        data: respData,
      }),
    );

    if (onSuccess) onSuccess({ ...respData, user: userData });
  } catch (error) {
    if (isAuth && error?.response?.status === 422) {
      if (onNotEligible) {
        onNotEligible();
      } else {
        yield put(pushErrorNotificationAction(t('streamingRewards.not_eligible.subtitle')));
      }
    }

    if (error?.response?.status === 404) {
      yield put(pushErrorNotificationAction(t('streamingRewards.rewardNotFound')));

      if (onError) onError();
    }

    if (error?.response?.status !== 422 && error?.response?.status !== 404) {
      yield put(pushErrorNotificationAction(error));
    }

    yield put(getStreamingRewards.fail());
  }
}

export function* checkStreamingRewardsSaga(action) {
  yield put(checkStreamingRewards.start());

  const { slug, mb_auth_code, params, onSuccess, onError } = action.payload;
  const { user: userData } = yield select(AuthSelectors.getAuthData);

  const url = getLink(STREAMING_REWARDS_CHECK, { slug });
  const partner = yield call(getPartnerData);
  const ipApiData = yield call(getIPInfoSaga);
  const zipcode = userData.zipcode || params?.zipcode || ipApiData?.zip || '';
  const confirmZipcode = confirmZipcodeAllowed && !userData.zipcode && !params?.zipcode;

  if (confirmZipcode) {
    yield put(
      manageConfirmZipDialogState.init({
        state: true,
        props: {
          zipcode: ipApiData?.zip || '',
          callback: data => {
            reduxStore.dispatch(checkStreamingRewards.init({ ...action.payload, params: data }));
          },
        },
      }),
    );

    return;
  }

  try {
    const { data: respData } = yield axios.post(
      url,
      { mb_auth_code, zipcode },
      {
        params: { partner: partner?.id },
      },
    );

    yield put(checkStreamingRewards.success({ data: respData }));

    yield put(userEditProfile.success({ user: respData.user }));

    yield put(pushSuccessNotificationAction(respData.reward.message));

    if (onSuccess) onSuccess({ userCard: respData.user?.card });
  } catch (error) {
    yield put(checkStreamingRewards.fail({ data: error.response?.data || {} }));

    if (onError) onError();
  }
}

export function* claimStreamingRewardsSaga(action) {
  yield put(claimStreamingRewards.start());

  const { app_id, callback, slug } = action.payload;
  const url = getLink(STREAMING_REWARDS_CLAIM, { slug });
  const partnerData = yield select(PartnerSelectors.getPartnerData);

  try {
    const { data: respData } = yield axios.post(
      url,
      { app_id },
      {
        params: { partner: partnerData.id },
      },
    );

    yield put(claimStreamingRewards.success({ data: respData }));

    yield put(userGetContentCount.init());

    if (callback) callback(respData.url);
  } catch (error) {
    const errorMessage = error.response?.data?.reward?.message ?? getErrorMessage(error);

    yield put(pushErrorNotificationAction(errorMessage));

    yield put(claimStreamingRewards.fail());
  }
}

export function* getVirtualCardExpandSaga(action) {
  yield put(getVirtualCardExpandedData.start());

  const callback = action?.payload?.callback;

  try {
    const { data: respData } = yield axios.get(VIRTUAL_CARD_GET_EXPANDED_DATA);

    yield put(getVirtualCardExpandedData.success({ data: { expand: respData } }));

    if (callback) callback(respData);
  } catch (error) {
    yield put(getVirtualCardExpandedData.fail());
  }
}

export function* getVirtualCardIframeTokenSaga(action) {
  yield put(getVirtualCardIframeToken.start());

  const { payment_method_id, onSuccess, onError } = action.payload;
  const url = getLink(VIRTUAL_CARD_GET_IFRAME_TOKEN, { payment_method_id });

  try {
    const { data: respData } = yield axios.get(url, {
      cache: { ignoreCache: true },
    });

    yield put(getVirtualCardIframeToken.success());

    if (onSuccess) onSuccess(respData);
  } catch (error) {
    yield put(getVirtualCardIframeToken.fail());

    if (onError)
      onError('Looks like we encountered a problem, please contact help@mybundle.tv to continue');
  }
}

export function* getSRClaimInstructionSaga(action) {
  yield put(getSRClaimInstruction.start());

  const { slug, app_slug, wp_slug, onError } = action.payload;
  const partnerData = yield call(getPartnerData);

  let instSlug = wp_slug;
  let instData = {};

  try {
    if (!instSlug) {
      const url = getLink(STREAMING_REWARDS_GET_CLAIM_INST, { slug, app_slug });

      const { data: respData } = yield axios.get(url, {
        params: { partner: partnerData.id },
      });

      instSlug = respData.wp_slug;
      instData = respData;
    }

    yield fork(getVirtualCardExpandSaga);

    if (instSlug) {
      yield fork(getWPPageSaga, { payload: { slug: instSlug } });
    }

    yield put(
      getSRClaimInstruction.success({
        data: instData,
      }),
    );
  } catch (error) {
    const statusCode = error?.response?.status;

    if (statusCode === 404 || statusCode === 403) {
      const respData = error?.response?.data || {};

      if (onError) onError({ statusCode, slug: respData.slug });
    } else {
      yield call(showErrorNotification, error);
    }

    yield put(getSRClaimInstruction.fail());
  }
}

export function* getSRImageAppsSaga(action) {
  const { slug } = action.payload;

  const url = getLink(STREAMING_REWARDS_GET_IMAGE_APPS, { slug });
  const partnerData = yield select(PartnerSelectors.getPartnerData);

  try {
    yield put(getImageApps.start());

    const { data: respData } = yield axios.get(url, {
      params: { partner: partnerData.id },
    });

    yield put(getImageApps.success({ data: respData.data }));
  } catch (error) {
    bugsnagClient.notify(new Error("Can't get apps for sr image", { cause: error }), event => {
      event.severity = 'info';
    });

    yield put(getImageApps.fail());
  }
}

export function* getSRAppsSearchSaga(action) {
  yield put(getSRAppsSearch.start(action.payload));

  const { params } = action.payload;

  try {
    let respData = { data: [] };

    if (params.search) {
      respData = yield axios.get(GET_DISCOVER, { params }).then(res => res.data);
    }

    yield put(getSRAppsSearch.success(respData));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getSRAppsSearch.fail());
  }
}

export function* completeRedeemSRSaga(action) {
  yield put(completeRedeemSR.start());

  const { slug, onSuccess } = action.payload;

  try {
    yield axios.post(STREAMING_REWARDS_COMPLETE_REDEEM, { slug });

    if (onSuccess) onSuccess();

    yield put(completeRedeemSR.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(completeRedeemSR.fail());
  }
}

export function* srClaimRewardCodeSaga(action) {
  yield put(srClaimRewardCode.start());

  const { params, onError } = action.payload;

  try {
    const { data: respData } = yield axios.post(STREAMING_REWARDS_CLAIM_REWARD_CODE, params);

    if (respData.promo_link) {
      const externalUrlParams = yield call(getExternalUrlParams);
      const updatedUrl = yield call(updateUrl, respData.promo_link, externalUrlParams);

      externalLink({
        href: updatedUrl,
        ...params,
      });

      window.location.href = updatedUrl;
    } else {
      if (onError) yield call(onError);

      yield put(pushErrorNotificationAction('Promo company was not found!'));
    }

    yield put(srClaimRewardCode.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    if (onError) yield call(onError);

    yield put(srClaimRewardCode.fail());
  }
}

export function* reissueSRCardSaga(action) {
  yield put(reissueSRCard.start());

  const { slug, params = {} } = action.payload;
  const { user: userData } = yield select(AuthSelectors.getAuthData);

  const url = getLink(STREAMING_REWARDS_REISSUE_CARD, { slug });
  const ipApiData = yield call(getIPInfoSaga);
  const zipcode = userData.zipcode || params?.zipcode || ipApiData?.zip || '';
  const confirmZipcode = confirmZipcodeAllowed && !userData.zipcode && !params?.zipcode;

  if (confirmZipcode) {
    yield put(
      manageConfirmZipDialogState.init({
        state: true,
        props: {
          zipcode: ipApiData?.zip || '',
          callback: data => {
            reduxStore.dispatch(
              reissueSRCard.init({
                ...action.payload,
                params: { ...action.payload.params, ...data },
              }),
            );
          },
        },
      }),
    );

    return;
  }

  try {
    const { data: respData } = yield axios.post(url, {
      ...params,
      zipcode,
    });

    yield fork(userGetCardsSaga, {});

    yield put(reissueSRCard.success({ data: respData }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(reissueSRCard.fail());
  }
}
